@import url("https://fonts.googleapis.com/css?family=Inter");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    color: #333;
}

p {
    text-align: start;
    /* space between lines */
    line-height: 1.5;
}
  
/* Add some padding. Padding on the left should be wider than that of the top and bottom */
.home-profile {
    padding: 1rem 9.375rem 1.25rem;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
}

@media screen and (max-width: 600px) {
    .home-profile {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
    }
}

.home-profile-name {
    font-size: 1.625rem;
    font-weight: 800;
    margin: 2rem 0;
}

/* No padding between lines */
#home-profile-l1 {
    padding : 0;
    margin : 0 0 1rem 0;
    line-height : 30px;
    font-size: 1.12em/*18px;*/
}

.home-index {
    padding: 60px 55px;
    background-color: #EE6969;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 50px;
    flex-grow: 1;
}

.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media screen and (max-width: 600px) {
    .home-index {
        padding: 2rem;
        background-color: #EE6969;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 50px;
    }
}

.home-index-title {
    font-size: 14px;
    color: #f5f5f5;
    padding : 0 35px 0 0;
    margin : 0;
    line-height: 24px;
    text-align: right;
    width: 150px;
    text-decoration: none;
}

.home-index-group-link {
    font-size: 20px;
    color: #333;
    text-decoration: none;
    padding : 0;
    margin : 0;
}

.row {
    display: flex;
}


.index-links {
    padding: 0 0 15px 0;
    margin: 0;
    line-height: 1;
}

.header-links {
    padding: 40px 55px;
    margin: 0;
    display: flex;
    justify-content: space-between;
}

.header-links-left {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
}

.header-links-right {
    padding: 0;
    margin: 0;
    display: flex;
}

.header-link-right {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
}

.header-sep {
    padding: 0;
    margin: 0;
}

.page {
    width: 100%;
    max-width: 616px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 100px;
}

.project-head {
    padding: 15px 0;
    margin: 0;  
}

.project-head-top {
    padding: 0;
    margin: 0;
    display: flex;
}

.project-head-text {
    font-size: 20px;
    padding: 0;
    margin: 0;
    font-weight: 600;
}

.project-head-link {
    font-size: 20px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    text-decoration: none;
    color: #4783dc;
}

.writing-link {
    font-size: 20px;
    padding: 0;
    margin: 0;
    font-weight: 600;
    color: #4783dc;
}

.line-break {
    display: block;
    margin-top: 0.5em;
}

.resume-iframe {
    position:absolute; 
    width:100%; 
    height:100%; 
    border:none; 
    margin:0 auto; 
    padding:0; 
}